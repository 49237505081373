import './App.css';
import {BrowserRouter as Router , Route ,Routes } from 'react-router-dom';
import axios from "axios";
import React, { useRef, useEffect, useState,Component } from 'react';
import Intro from './pages/Intro';
import Liveness from './pages/Liveness';
import Success from './pages/Success';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

localStorage.setItem('userAgent', window.navigator.userAgent);

const host_url = 'http://localhost:3000/';

function App()
{
	const [state,setstatus]=React.useState(0);
	const [apierror,setapierror]=React.useState(0);
	const [message,setmessage]=React.useState('');
	const senddata = async()=> {		
		const queryParams = new URLSearchParams(window.location.search)
		let uuid = queryParams.get("uuid");
		
		if(uuid === undefined || uuid === null) 
		{	
			uuid = localStorage.getItem('uuid');	
		}					
		let data = JSON.stringify({
			"uuid": uuid,
		});		
		let config = {
			method: 'POST',
			mode: 'cors',	
			url: 'https://login.codegotech.com/webapi/checkuuid',
			headers: {
				'Content-Type': 'application/json',				
			},
			data : data
		};		
		await axios.request(config)
		.then((response) => {
			
			if(response.status == 200)
			{				
				if(response.data.status == 1)
				{		
					
					localStorage.setItem('isalreadycheck', response.data.is_already_check);		
					localStorage.setItem('kyctype', response.data.type);
					localStorage.setItem('uuid', uuid);
					setstatus(response.data.status);	
					if(response.data.type == 'partial')
					{
						if(response.data.liveness === 1)
						{
							localStorage.setItem('comImage',  response.data.comImage);
							localStorage.setItem('partial_page', 'LIVENESS');
						}else if(response.data.id_proof === 1)
						{
							localStorage.setItem('partial_page', 'ID');
						}else if(response.data.address_proof === 1)
						{
							localStorage.setItem('partial_page', 'ADDRESS');
							
						}						
					}else{	
						localStorage.setItem('address_proof', response.data.address_proof);
					}
				}else{		
					setstatus(response.data.status);
					setmessage(response.data.message);
				}				
			}else{
				setstatus(0);
				setmessage('unauthorized access');
			} 
			//console.log(JSON.stringify(response.data));
		}).catch((error) => {
			console.log(error);
		});  
		
	}
	const uuid = localStorage.getItem('uuid');
	
	let qruurl = 'https://chart.googleapis.com/chart?cht=qr&chs=170x170&chl='+host_url+'?uuid='+uuid+'&choe=UTF-8&chld=L';
	
	useEffect(() => {
	
    senddata()
	},[]);
	
	return (
		<div className="App">			
		{state === 1 ?  	
			<Router>
				<Routes>
				<Route path='/' element={<Intro />} />
					<Route path='/liveness' element={<Liveness />} />
					<Route path='/success' element={<Success />} />
				</Routes>        
			</Router> 
			: <div className="titlemsg"><div className='warning'>
				<img src='warning-sign.png' alt="Codego warning sign" />							
				</div>{message}</div>			
		}
		</div>
	);
}
export default App;
