import React ,{ useState } from "react" ;
import { useNavigate  } from "react-router-dom";

const Intro = () => {
	
	const [btn , setbtn] = useState("btn btn-primary");
	const [btntxt , setbtntxt] = useState("Continue");
	const [btnicon , setbtnicon] = useState("fa-solid fa-arrow-right");
	
    const navigate = useNavigate();
	
	
	
    const nextpage= ()=>{
		setbtntxt("");
		setbtn("btn btn-success");
		setbtnicon("fa-solid fa-check");
		
		const isalreadycheck = localStorage.getItem('isalreadycheck');	
		//console.log(isalreadycheck);
		
		const kyctype = localStorage.getItem('kyctype');
		
		if(kyctype === 'partial')
		{
			const partial_page = localStorage.getItem('partial_page');
			if(partial_page === 'LIVENESS')
			{
				setTimeout(function(){	navigate('/liveness') }, 500);				
			}else if(partial_page === 'ID')
			{
				setTimeout(function(){	navigate('/Passport') }, 500);
			}else if(partial_page === 'ADDRESS')
			{
				setTimeout(function(){	navigate('/proof') }, 500);
			}
			
		}else{
			if(isalreadycheck == 0)
			{
				setTimeout(function(){	navigate('/kycalready') }, 500);
			}else{
				setTimeout(function(){	navigate('/personal') }, 500);
			}
		}
		
    }
    return(
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-6 offset-md-3">				
					<div className="row top_divs">
						
						<div className="cards">
							
							<div className="card-body">		
								<div className="kycimg"><img src="customer.png" alt="kyc-user" /></div>
								<h2 className="ttile_head">To continue, we need to <b>verify your identity</b></h2>
								<ul className="intr">
									<li>Before  you start, please prepare your identity document and make sure it is valid.</li>
									<li>We also require you to agree to our process of your personal data..</li>
								</ul>

								<div className="collect-steps">
									<div className="step-row">										
										<div className="steps">											
											<h3><img src="flash.png" className="listicon"/> <span className="titlep">Fast and secure</span></h3>
											<p>Verification usually takes less than a few minutes and is encrypted</p>
										</div>
									</div>
									<div className="step-row">										
										<div className="steps">											
											<h3><img src="lock.png" className="listicon"/> <span className="titlep">How we verify your</span></h3>
											<p>To learn how our service provider uses data you provide and device data, see their <a href="">Privacy Satement</a></p>
										</div>
									</div>
									
								</div>
								
								<div className="row">	
									<div className="collect-steps"><button className={btn} onClick={nextpage} >{btntxt} <i className={btnicon}></i></button>			
									</div>
								</div>
								
								<div className="row footer">	
									<div className="collect-steps_1">CODEGO | Technologies</div>
								</div>
							</div>									
						</div>									
					</div>									
				</div>
			</div>
		</div>	
    );
};
export default Intro;