import React ,{ useEffect, useState } from "react" ;
import {Link}  from 'react-router-dom'
const Success = () => {
    return (<div className="container-fluid">
		<div className="row">
			<div className="col-md-12 col-sm-12 col-xs-12">				
				<div className="row top_div">					
					<div className="logo_div text-center">
						<img src="codego.png" className="img-responsive logo_img"/>
					</div>
					<div className="">
						<div className="card_c">
							<div className="card-header text-center">							
								<h2 className="card_head">Thank you!</h2>								
							</div>
							<div className="card-body sucess_div">
								<img src="checked.png" className="sucess"/>
								<div className="text-center">	
									<br/>
									<p>Your liveness status will update shortly.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
};
export default Success;