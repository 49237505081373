//import logo from '../logo.svg';
import '../App.css';
import { FaceLivenessWebComponent } from '@regulaforensics/vp-frontend-face-components';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import   axios from "axios";
const containerStyle = {
  display: 'flex',  
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
};

const buttonStyle = {
  padding: '10px 30px',
  color: 'white',
  fontSize: '16px',
  borderRadius: '2px',
  backgroundColor: '#0d6efd',
  border: '1px solid #0d6efd',
  cursor: 'pointer',
  fontWeight: '500',
  borderRadius: '8px'
};

function App() {
  const [btnicon , setbtnicon] = useState("fa-solid fa-arrow-right");
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const componentRef = useRef(null);
  const [livenessResult, setLivenessResult] = useState(null);
  const navigate = useNavigate();
  
  const getDatas = async () => {
		const res = await axios.get("https://api.ipify.org/?format=json");
		// console.log(res.data);
		localStorage.setItem('Ip-Adrees', res.data.ip);
	};
	
	let uuid, ipAdress,passportName,passportsurname,birthday,passportcountry,Gender,passportNumber,passportexpire,proofName,proofIssuerAddress,proofIsuueName,proofReciptAddress,proofDate,proofPhoto,proofDate_1,userAgent , partial_page , kyctype,isalreadycheck, profilepic;
	const getData =()=>{
		partial_page = localStorage.getItem('partial_page');
		kyctype = localStorage.getItem('kyctype');
		userAgent = localStorage.getItem('userAgent');		
		ipAdress = localStorage.getItem('Ip-Adrees');
		uuid = localStorage.getItem('uuid');
		isalreadycheck = localStorage.getItem('isalreadycheck');
		profilepic = localStorage.getItem('comImage');
	}
	
	const listener = (data) => {
	//console.log(data.isTrusted);  
	//console.log(data);  
	
	
    if (data.detail.action === 'PROCESS_FINISHED') 
	{
		//if(data.isTrusted == true)
		//{
			//alert(data.isTrusted);
			//alert(data);
			//console.log(data.isTrusted);
			console.log(data.detail.data.response);
						
			//if((data.detail.data.status === 1 && data.detail.data.response) && (data.detail.data.response.code === 0)) 
			if((data.detail.data.status === 1 && data.detail.data.response)) 
			{
				getData();
				let postdata = JSON.stringify({
					//"isTrusted":data.isTrusted,
					"is_already_check":isalreadycheck,
					"partial_page":partial_page,
					"kyctype":kyctype,
					"uuid":uuid,
					"userAgent": userAgent,				
					"ip_address": ipAdress,				
					"profilepic": profilepic,				
					"rightsidephot": '',
					"leftsidephoto": '',
					//"smilephoto":data.detail.data.response.images[0]				
					"smilephoto":data.detail.data.response.capture[0]				
				});
				let config = {
					  method: 'post',
					 'mode':'cors',
					  url: 'https://login.codegotech.com/webapi/send_userdata',
					  headers: {
						'Content-Type': 'application/json'
					  },
					  data : postdata
				};
				
				axios.request(config).then((response) => {
					//console.log(response.status);
					//if(response.status===200)
					//{				
						localStorage.removeItem('isalreadycheck');					
						localStorage.removeItem('kyctype');					
						localStorage.removeItem('partial_page');					
						localStorage.removeItem('userAgent');					
						localStorage.removeItem('Ip-Adrees');
						localStorage.removeItem('proofPhoto');
						localStorage.removeItem('passportPhoto');
						localStorage.removeItem('uuid');
						navigate('/success');			
					//}		  
				}).catch((error) => {
					console.log(error);
				}); 
			}else{
				//alert(data.detail.data.reason);
			} 			
		//}else{
			//alert('Please try again.');
		//}			
    }

    if (data.detail?.action === 'CLOSE' || data.detail?.action === 'RETRY_COUNTER_EXCEEDED') {
      setIsOpen(false);
    }
	};
  
	
	useEffect(() => {
	 getDatas(); 
    if (isOpen && componentRef.current) {
      componentRef.current.settings = {
		locale: 'en',
		videoRecording: false,  
		//url: 'https://faceapi.regulaforensics.com/',  
		url: 'https://facesdk.codegotech.com/',  
        headers: {
			Authorization: 'Basic QWxhZGRpbjpvcGVuIHNlc2FtZQ==',
        },
        tag: '123',
        customization: {			
			fontFamily: 'Noto Sans, sans-serif',
			fontSize: '16px',
			onboardingScreenStartButtonBackground: '#141774',
			onboardingScreenStartButtonBackgroundHover: '#7c45b4',
			onboardingScreenStartButtonTitle: '#FFFFFF',
			onboardingScreenStartButtonTitleHover: '#FFFFFF',
			cameraScreenFrontHintLabelBackground: '#E8E8E8',
			//onboardingScreenIllumination: 'https://path-to-image.com',
			//onboardingScreenAccessories: 'data:image/svg+xml;base64,PHN2...',
			//onboardingScreenCameraLevel: importedImage,
			cameraScreenFrontHintLabelText: '#000000',
			cameraScreenSectorActive: '#141774',
			cameraScreenSectorTarget: '#BEABE2',
			cameraScreenStrokeNormal: '#141774',
			processingScreenProgress: '#141774',
			retryScreenRetryButtonBackground: '#141774',
			retryScreenRetryButtonBackgroundHover: '#141774',
			retryScreenRetryButtonTitle: '#FFFFFF',
			retryScreenRetryButtonTitleHover: '#FFFFFF',
        },
      };
    }
  }, [isOpen]);

  useEffect(() => {
    const containerCurrent = containerRef.current;

    if (!containerCurrent) return;

    containerCurrent.addEventListener('face-capture', listener);

    return () => {
      containerCurrent.removeEventListener('face-capture', listener);
    };
  }, []);

  return (
    <div className="App">
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-6 offset-md-3">
					<div className="row top_divs">						
						<div className="cards">							
							<div className="card-body">		
								<div className="kycimg"></div>
								<h2 className="ttile_head"><b>Liveness</b></h2>
								{!isOpen && <p className="ttile_head">Press Start! and look to the camera</p>}						
								<div style={containerStyle} ref={containerRef}>
								{isOpen ? (
								  <face-capture ref={componentRef} ></face-capture>
								) : (
								  <button style={buttonStyle} onClick={() => setIsOpen(true)}>
									Start Liveness <i className={btnicon}></i>
								  </button>
								)}
								</div>		
							</div>
						</div>
					</div>
					<div className="row footer">	
						<div className="collect-steps_1">CODEGO | Technologies</div>
					</div>
				</div>
			</div>
		</div>		
    </div>
  );
}

export default App;
